import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducers from '../reducers';

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    ...reducers
  })
);

export default function storeArgs() {
  let store = createStore(persistedReducer);
  let persistor = persistStore(store);
  return { store, persistor }
}