import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import React, { Suspense, lazy, Fragment } from 'react';

import {
    ToastContainer,
} from 'react-toastify';

const Dashboards = lazy(() => import('../../Pages/Dashboards'));
const Settings = lazy(() => import('../../Pages/Settings'));
const ExternalModify = lazy(() => import('../../Pages/ExternalModify'));
const PolishPanel = lazy(() => import('../../Pages/PolishPanel'));
const LoginPage = lazy(() => import('../../Pages/Login'));

class AppMain extends React.Component {
    render() {
        return (
            <Fragment>

                {/* Login page */}

                <Route exact path="/" render={() => (
                    this.props.isAuthenticated ? <Redirect to="/pl/exhibitors" /> : <Redirect to="/login" />
                )} />
                <ToastContainer />

                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <h6 className="mt-5">
                                Loading...
                            </h6>
                        </div>
                    </div>
                }>
                        <Route path="/login" component={LoginPage} />
                </Suspense>
                {/* Polish Panel */}

                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <h6 className="mt-5">
                                Trwa ładowanie ekranu z narzędziami do modyfikowania zawartości bazy danych...
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/pl" component={this.props.isAuthenticated ? PolishPanel : LoginPage} />
                </Suspense>

                {/* Dashboards */}
                {/* 
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <h6 className="mt-3">
                                Trwa ładowanie głównego ekranu...
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/dashboards" component={this.props.isAuthenticated ? Dashboards : LoginPage} />
                </Suspense> */}

                {/* Settings */}

                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <h6 className="mt-5">
                                Trwa ładowanie ekranu ustawień...
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/settings" component={this.props.isAuthenticated ? Settings : LoginPage} />
                </Suspense>

                {/* External modify modal page */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <h6 className="mt-5">
                                Trwa ładowanie ekranu...
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/modify/:id" component={ExternalModify} />
                </Suspense>

            </Fragment>
        )
    }
};

const mapStateToProps = state => ({
    isAuthenticated: state.SystemData.isAuthenticated
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AppMain);