/* eslint-disable default-case */
export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';

export const setIsAuthenticated = isAuthenticated => ({
    type: SET_IS_AUTHENTICATED,
    isAuthenticated
});

export default function reducer(state = {
    isAuthenticated: false
}, action) {
    switch(action.type) {
        case SET_IS_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: action.isAuthenticated
            };
    }
    return state;
}